<template>
  <div class="container_banner">
    <div
      class="wrap_banner"
      :style="{
        transition: wrapStyle.transition,
        transform: `translateY(${wrapStyle.translateY}px)`
      }"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <div
        v-for="(banner, index) in banners"
        :key="index"
        class="item_banner"
        :class="[banner.commentType, banner.url ? 'link' : '']"
        :style="{ top: banner.top }"
        @click="onClickBanner(banner)"
      >
        <atoms-c-icon
          class="icon_type"
          :icon-name="iconByType[banner.commentType]"
        />
        <span>{{ banner.comment }}</span>
        <div v-if="banner.url" class="btn_link">
          바로가기
          <atoms-c-icon icon-name="arrow_right"></atoms-c-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { $api } = useNuxtApp()
const BANNER_HEIGHT = {
  PC: 62,
  OTHER: 82
}
const TRANSITION = {
  NONE: 'none',
  MOVE: 'transform 0.5s ease-out'
}
const isHover = ref(false)
const wrapStyle = reactive({
  translateY: 0,
  transition: TRANSITION.MOVE
})
const iconByType: Record<BannerType, string> = {
  NOTICE: 'speaker',
  MAINTENANCE: 'repair',
  ERROR: 'warning'
}
const { data: banners } = await useAsyncData(
  () => $api.banner.getUrgentBanners(),
  {
    transform(res) {
      if (res.data.length > 1) {
        return [...res.data, res.data[0]]
      }
      return res.data
    },
    default(): Banner[] {
      return []
    }
  }
)
let curBannerHeight = BANNER_HEIGHT.PC
const delay = 5000 // 각 배너가 보여지는 시간 (3초)
let currentBannerIndex = 0
let lastAnimation = 0
function rollingBanners(timestamp: number) {
  if (!isHover.value && timestamp - lastAnimation > delay) {
    wrapStyle.translateY = -(currentBannerIndex + 1) * curBannerHeight // 다음 배너로 이동

    currentBannerIndex++
    if (currentBannerIndex >= banners.value.length - 1) {
      currentBannerIndex = 0
      setTimeout(() => {
        wrapStyle.transition = TRANSITION.NONE // 첫 배너로 순간 이동할 때는 트랜지션 없음
        wrapStyle.translateY = 0
        setTimeout(() => {
          wrapStyle.transition = TRANSITION.MOVE // 트랜지션 다시 활성화
        }, 50) // 브라우저가 스타일 변경을 인지할 수 있게 잠시 기다림
      }, 500) // 현재 트랜지션 시간
    }

    lastAnimation = timestamp
  }
  requestAnimationFrame(rollingBanners)
}

function onMouseEnter() {
  isHover.value = true
}
function onMouseLeave() {
  isHover.value = false
}

function setBannerHeight(height: number) {
  curBannerHeight = height
  document.documentElement.style.setProperty('--bannerHeight', `${height}px`)
}
/**
 * 브라우저 넓이 감지 Listener 등록 및 최초 배너 높이 설정
 */
function addMqlListener() {
  // 브라우저 넓이 변경 감지
  const mql = window.matchMedia('screen and (max-width: 999px)')

  const onChangeMql = (event: any) => {
    if (!banners.value.length) {
      return
    }
    if (event.matches) {
      setBannerHeight(BANNER_HEIGHT.OTHER)
    } else {
      setBannerHeight(BANNER_HEIGHT.PC)
    }
  }

  mql.removeEventListener('change', onChangeMql)
  mql.addEventListener('change', onChangeMql)
  onChangeMql(mql)
}

function onClickBanner(banner: Banner) {
  if (banner.url) {
    window.open(banner.url, '_blank')
  }
}
onMounted(() => {
  const bannerCnt = banners.value.length
  if (bannerCnt) {
    addMqlListener()
  }
  if (bannerCnt > 1) {
    requestAnimationFrame(rollingBanners)
  }
})
</script>
<style scoped lang="scss">
.container_banner {
  z-index: 12;
  position: fixed;
  top: 0;
  flex-shrink: 0;
  width: 100%;
  height: $bannerHeight;
  overflow: hidden;
  .wrap_banner {
    height: inherit;
    .item_banner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: inherit;
      color: #ffffff;
      gap: 4px;
      font-size: 15px;
      line-height: 1.47;
      letter-spacing: -0.38px;
      &.NOTICE {
        background-color: #1998b8;
      }
      &.MAINTENANCE {
        background-color: #626880;
      }
      &.ERROR {
        background-color: #e5594d;
      }
      &.link {
        cursor: pointer;
      }
      .icon_type {
        flex-shrink: 0;
        margin-top: 2px;
      }
      .btn_link {
        padding-left: 8px;
        display: flex;
        flex-shrink: 0;
        font-weight: bold;
        align-items: center;
      }
    }
  }
}
@media (max-width: 999px) {
  .container_banner {
    .wrap_banner {
      .item_banner {
        padding: 20px;
        justify-content: left;
        align-items: start;
        font-size: 15px;
        position: relative;
        box-sizing: border-box;
        .icon_type {
          display: none;
        }
        .btn_link {
          padding-left: 0;
          position: absolute;
          right: 20px;
          bottom: 18px;
        }
      }
    }
  }
}
@media (max-width: 599px) {
  .container_banner {
    .wrap_banner {
      .item_banner {
        padding: 16px;
        font-size: 12px;
        .btn_link {
          bottom: 12px;
          right: 16px;
          i {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}
</style>
